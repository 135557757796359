<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :mini-variant="expand"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
    @update="() => console.log('111')"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item
      two-line
      to="/"
    >
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular text-h3">
          <v-icon class="logo-mini">
            mdi-all-inclusive
          </v-icon>
          <span class="logo-normal">ПОРТАЛ <small>{{ vers }}</small></span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list-item>
      <v-list-item-icon class="mt-5">
        <v-icon v-text="'mdi-account-circle-outline'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="fio" />
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="logout"
            >
              <v-icon color="grey lighten-3">mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Выход</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>

    <v-divider class="mb-1" />

    <template v-for="(item, i) in computedinfoItems">
      <span :key="`s-item-${i}`">
        <base-item-group
          v-if="item.children&&canAccess(item)"
          :key="`group-${i}`"
          :item="item"
          :dev="dev"
        >
          <!--  -->
        </base-item-group>
        <base-item
          v-else-if="canAccess(item)"
          :key="`item-${i}`"
          :item="item"
        />
      </span>
    </template>
    <v-divider class="mb-1" />
    <!--v-list
      dense
      nav
    >
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    -->
    <!-- Style cascading bug  -->
    <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
    <div />

    <template v-for="(item, i) in computedItems">
      <span :key="`s2-item-${i}`">
        <base-item-group
          v-if="item.children&&canAccess(item)"
          :key="`group-${i}`"
          :item="item"
          :dev="dev"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else-if="canAccess(item)"
          :key="`item-${i}`"
          :item="item"
        />
      </span>
    </template>
    <!-- Style cascading bug  -->
    <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
    <div />
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      expand: true,
      infoItems: [
        {
          icon: 'mdi-phone-classic',
          title: 'Телефонный справочник',
          to: '/telefon',
          rights: 'basic',
        },
        {
          icon: 'mdi-phone-forward',
          title: 'Собрания (beta)',
          to: '/meetings',
          rights: 'conferences',
        },
        {
          icon: 'mdi-book-information-variant',
          title: 'Инструкции',
          to: '/instructions',
          rights: 'basic',
        },
        {
          icon: 'mdi-book-information-variant',
          title: 'Инструкции wiki',
          // to: '/instructionswiki',
          rights: 'basic',
          href: 'http://wiki/login',
        },
        {
          icon: 'mdi-page-next',
          title: 'Мои заявки',
          to: '/mytikets',
          rights: 'basic',
        },
        {
          icon: 'mdi-inbox-arrow-down-outline',
          title: 'Мои очереди',
          to: '/myqueues',
          rights: 'basic',
        },
      ],
      items: [
        {
          icon: 'mdi-calendar-clock',
          title: 'Сверка СКУД',
          to: '/time',
          rights: 'timex',
        },
        {
          icon: 'mdi-calendar-star',
          title: 'Отпуска',
          group: '/vacations',
          rights: 'vacations',
          children: [
            {
              to: 'vacations',
              icon: 'mdi-calendar-clock',
              title: 'График отпусков',
              rights: 'vacations',
            },
            {
              to: 'vacationsCons',
              icon: 'mdi-chart-timeline',
              title: 'График сводный',
              rights: 'vacations',
            },
          ],
        },
        {
          icon: 'mdi-chef-hat',
          title: 'Кейтеринг',
          group: '/simplefoods',
          rights: 'simplefoods',
          children: [
            {
              to: 'timesheet',
              icon: 'mdi-calendar-clock',
              title: 'Табель',
              rights: 'simplefoods',
            },
            {
              to: 'consolidated',
              icon: 'mdi-calendar-clock',
              title: 'Сводный',
              rights: 'simplefoods',
            },
            {
              to: 'performance',
              icon: 'mdi-table-edit',
              title: 'Производительность',
              rights: 'simplefoods',
            },
            {
              to: 'perdayperformance',
              icon: 'mdi-food',
              title: 'Дневная выработка',
              rights: 'simplefoods',
            },
          ],
        },
        {
          icon: 'mdi-text-box-multiple-outline',
          title: 'Договоры',
          to: '/contracts',
          rights: 'contracts',
        },
        {
          icon: 'mdi-currency-rub',
          title: 'Доходы и расходы',
          to: '/incomeExpenses',
          rights: 'incomeExpenses',
        },
        // {
        //   icon: 'mdi-text-box-edit-outline',
        //   title: 'Редактор договоров',
        //   to: '/templates',
        //   rights: 'templates',
        // },
        {
          icon: 'mdi-chart-areaspline',
          title: 'Планирование и отчетность',
          to: '/planning',
          rights: 'planning',
        },
        {
          icon: 'mdi-chart-box-plus-outline',
          title: 'Редактор отчетов',
          to: '/reportEditor',
          rights: 'planning',
        },
        {
          icon: 'mdi-table-large-plus',
          title: 'Конструктор шаблонов NEW',
          to: '/builderTemplateIndex',
          rights: 'constructor',
        },
        {
          icon: 'mdi-bullseye-arrow',
          title: 'Сайт админ',
          group: '/siteadmin',
          rights: 'website_admin',
          children: [
            {
              to: 'sections',
              icon: 'mdi-book-open-page-variant-outline',
              title: 'Разделы',
              rights: 'website_admin',
            },
            {
              to: 'news',
              icon: 'mdi-apple-safari',
              title: 'Новости',
              rights: 'website_admin',
            },
            {
              to: 'interesting',
              icon: 'mdi-chat-alert-outline',
              title: 'Интересное',
              rights: 'website_admin',
            },
            {
              to: 'promotion',
              icon: 'mdi-alert-octagram-outline',
              title: 'Акции',
              rights: 'website_admin',
            },
            {
              to: 'comment',
              icon: 'mdi-chat-processing-outline',
              title: 'Отзывы',
              rights: 'website_admin',
            },
            {
              to: 'directions',
              icon: 'mdi-axis-arrow',
              title: 'Направления',
              rights: 'website_admin',
            },
          ],
        },
        {
          group: '/templates',
          icon: 'mdi-image',
          title: 'templates',
          rights: 'dev1',
          children: [
            {
              group: '/pages',
              icon: 'mdi-image',
              title: 'pages',
              children: [
                {
                  title: 'pricing',
                  to: 'pricing',
                },
                {
                  title: 'rtl',
                  to: 'rtl',
                },
                {
                  title: 'timeline',
                  to: 'timeline',
                },
                {
                  title: 'login',
                  to: 'login',
                },
                {
                  title: 'register',
                  to: 'pricing',
                },
                {
                  title: 'lock',
                  to: 'lock',
                },
                {
                  title: 'user',
                  to: 'user',
                },
                {
                  title: 'error',
                  to: '404',
                },
              ],
            },
            {
              group: '/components',
              icon: 'mdi-view-comfy',
              title: 'components',
              children: [
                {
                  title: 'multi',
                  group: '',
                  children: [
                    {
                      title: 'example',
                      href: '#',
                    },
                  ],
                },
                {
                  title: 'buttons',
                  to: 'buttons',
                },
                {
                  title: 'grid',
                  to: 'grid-system',
                },
                {
                  title: 'tabs',
                  to: 'tabs',
                },
                {
                  title: 'notifications',
                  to: 'notifications',
                },
                {
                  title: 'icons',
                  to: 'icons',
                },
                {
                  title: 'typography',
                  to: 'typography',
                },
              ],
            },
            {
              group: '/forms',
              icon: 'mdi-clipboard-outline',
              title: 'forms',
              children: [
                {
                  title: 'rforms',
                  to: 'regular',
                },
                {
                  title: 'eforms',
                  to: 'extended',
                },
                {
                  title: 'vforms',
                  to: 'validation',
                },
                {
                  title: 'wizard',
                  to: 'wizard',
                },
              ],
            },
            {
              group: '/tables',
              icon: 'mdi-grid',
              title: 'tables',
              children: [
                {
                  title: 'rtables',
                  to: 'regular-tables',
                },
                {
                  title: 'etables',
                  to: 'extended-tables',
                },
                {
                  title: 'dtables',
                  to: 'data-tables',
                },
              ],
            },
            {
              group: '/maps',
              icon: 'mdi-map-marker',
              title: 'maps',
              children: [
                {
                  title: 'google',
                  to: 'google-maps',
                },
                {
                  title: 'fullscreen',
                  to: 'full-screen-map',
                },
              ],
            },
            {
              icon: 'mdi-widgets',
              title: 'widgets',
              to: '/widgets',
            },
            {
              icon: 'mdi-calendar-range',
              title: 'calendar',
              to: '/calendar',
            },
          ],
        },
      ],
      // process.env.NODE_ENV === 'development' ? true : false,
    }),
    computed: {
      ...mapState(['barColor', 'barImage', 'fio', 'dev', 'vers']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      fio () {
        return this.$store.state.foremanFIO.fioshort
      },
      foremanFIO () {
        return this.$store.state.foremanFIO
      },
      RightsList () {
        return this.$store.state.foremanFIO.rightslist ? this.$store.state.foremanFIO.rightslist.toLowerCase() : ''
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      computedinfoItems () {
        return this.infoItems.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          group: '',
          title: this.$t('avatar'),
          children: [
            {
              href: '',
              title: this.$t('my-profile'),
            },
            {
              to: '',
              title: this.$t('edit-profile'),
            },
            {
              to: '',
              title: this.$t('settings'),
            },
          ],
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    methods: {
      logout () {
        console.log('logout')
        this.$store.dispatch('authLogout')
        this.$router.push('/pages/login')
      },
      canAccess (item) {
        if (this.RightsList.includes(item.rights.toLowerCase()) || this.dev || item.rights == '') return true
        return false
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px

</style>
